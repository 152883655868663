import jQuery from 'jquery';

jQuery.noConflict = () => jQuery; // just do nothing, else throw "Cannot set property $ of #<Window> which has only a getter"

// Used by:
// - some inline scripts in templates authored by us
// - TagCommander client API (ex: tC.addClass(), tC.removeClass(), tC.toggleClass(), etc.)
// - some TagCommander tags/scripts configuration
// - some Kameleoon A/B test and personalization client scripts
// - some SMILE pages and HTML "strates"

let warnGlobalJQueryUsage = true;
// tmp fix for scripts that should import jQuery instead of use global property
const getjQuery = {
  get: () => {
    if (warnGlobalJQueryUsage) {
      console.warn('Use global jQuery is depreciated, use native DOM API or \'import $ from "jquery";\' instead');
    }
    warnGlobalJQueryUsage = false;
    return jQuery;
  },
};

Object.defineProperties(window, {
  jQuery: getjQuery,
  $: getjQuery,
});
