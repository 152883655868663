import componentRegistry from '../registry.js';
import translation from '../../helpers/translation/translation.js';
import renderExpander from './expander.liquid';
import DebounceEventListener from '../../helpers/event/debounce-listener.js';
import { scrollMarginTop } from '../../helpers/view/scrollIntoView.js';
import './expander.less';
import parseHTML from '../../helpers/dom/parseHTMLFragment.js';

const CSS_PREFIX = 'expander';
const CLASSNAMES = {
  COLLAPSED: 'collapsed',
  HAS_LABELS: 'hasLabels',
  HAS_ICON: 'hasIcon',
  NO_STYLE: 'nostyle',
};
const DEFAULT_VISIBLE_HEIGHT = 150;
const AUTO_VIS_HEIGHT_ITEMS = 2;

/**
 * Expander Hides extra content and displays a toggle button
 *
 * @param {HTMLElement} el The DOM element
 * @example
 *   <div class="js-Expander">...</div>
 *   <div class="js-Expander" data-visible-height="200">...</div>
 *   <div class="js-Expander" data-visible-height="auto">...</div>
 *   <div class="js-Expander" data-textOn="..." data-textOff="..." data-button-classes="...">...</div>
 */
class Expander {
  constructor({ el }) {
    this.el = el;
    this.textLabels = {
      on: this.el.dataset.textOn,
      off: this.el.dataset.textOff,
    };
    this.hasLabels = !!(this.textLabels.on && this.textLabels.off);

    // Generating HTML structure
    this.el.classList.add(CSS_PREFIX, `${CSS_PREFIX}--${this.hasLabels ? CLASSNAMES.HAS_LABELS : CLASSNAMES.HAS_ICON}`);
    const children = parseHTML(
      renderExpander({
        buttonClasses: el.dataset.buttonClasses || CLASSNAMES.NO_STYLE,
        hasIcon: !this.hasLabels,
        dataAutomationButton: this.el.dataset.automationButton,
        dataAutomationContent: this.el.dataset.automationContent,
      })
    );
    this.content = children.querySelector(`.${CSS_PREFIX}__content`);
    this.content.append(...this.el.childNodes);
    this.mask = children.querySelector(`.${CSS_PREFIX}__mask`);
    this.button = children.querySelector(`.${CSS_PREFIX}__button`);
    this.el.replaceChildren(children);

    this.setButtonText();

    // Content visible height (in px)
    // Default can be overwritten by dataset attribute or this.el CSS max-height.
    // Dataset attribute can be 'auto' or int value.
    const inputHeight = this.el.dataset.visibleHeight;
    this.auto = inputHeight === 'auto';
    this.visibleHeight =
      (this.auto && this.getAutoVisibleHeight()) ||
      parseInt(inputHeight) ||
      parseInt(getComputedStyle(this.el).getPropertyValue('max-height')) ||
      DEFAULT_VISIBLE_HEIGHT;

    // First size calculation
    this.resize();

    // Observe content changes
    const observer = new MutationObserver(() => {
      this.resize();
      observer.takeRecords();
    });
    observer.observe(this.el, { childList: true, subtree: true, attributes: true });

    // Handlers
    this.button.addEventListener('click', this.toggle.bind(this));
    window.addEventListener('resize', new DebounceEventListener(() => this.resize(), 200));
  }

  /** @returns {number} Height value, based on child elements heights */
  getAutoVisibleHeight() {
    // 'auto' inputHeight option will get this.content first [AUTO_VIS_HEIGHT_ITEMS] childs heights.
    // Returns 0 if 'auto' && no children
    return [...this.content.children]
      .slice(0, AUTO_VIS_HEIGHT_ITEMS)
      .reduce(
        (accumulator, current) =>
          accumulator +
          Math.ceil(current.getBoundingClientRect().height) +
          parseInt(getComputedStyle(current).getPropertyValue('margin-top')),
        0
      );
  }

  /** Sets mask position on resize */
  setHeight() {
    this.el.style.maxHeight = this.isCollapsed ? this.endHeight : this.startHeight;
  }

  /** Toggles text label or arrows title */
  setButtonText() {
    if (this.hasLabels) {
      this.button.innerHTML = this.isCollapsed ? this.textLabels.off : this.textLabels.on;
    }

    const textValue = this.isCollapsed
      ? this.el.dataset.titleOn || translation('assets.javascript.expander.seeless')
      : this.el.dataset.titleOff || translation('assets.javascript.expander.seemore');

    ['title', 'aria-label'].forEach((a) => {
      this.button.setAttribute(a, textValue);
    });
  }

  /** Toggles content and button */
  toggle() {
    this.isCollapsed = !this.el.classList.contains(CLASSNAMES.COLLAPSED);
    this.setHeight();
    this.setButtonText();
    this.button.setAttribute('aria-pressed', this.isCollapsed);
    this.content.setAttribute('aria-expanded', this.isCollapsed);
    this.el.classList.toggle(CLASSNAMES.COLLAPSED);
    this.handleScroll();
  }

  /**
   * When expander is opened: we save initialHeight of content When expander is closed: we scroll by diff height of the
   * content plus offset
   */
  handleScroll() {
    if (this.isCollapsed) {
      this.initialHeight = this.el.clientHeight;
    } else {
      const marginTop = scrollMarginTop();
      // if the block fully visible dont scroll
      if (this.el.getBoundingClientRect().y - marginTop < 0) {
        const visibleHeight = this.getVisibleHeight(); // height of visible content
        const offsetHeight = visibleHeight >= this.initialHeight ? 0 : this.initialHeight - visibleHeight; // height of invisible content
        const totalOffsetHeight = offsetHeight + marginTop;
        let scroll = this.el.clientHeight - this.initialHeight;
        if (this.initialHeight + totalOffsetHeight > visibleHeight) {
          scroll += totalOffsetHeight;
        }
        document.scrollingElement.scrollBy({
          top: -scroll,
          behavior: 'smooth',
        });
      }
    }
  }

  /** Resize checks if expand is needed */
  resize() {
    // Auto mode will adjust visible height regarding child elements height
    if (this.auto) {
      this.visibleHeight = this.getAutoVisibleHeight();
    }

    if (this.visibleHeight <= this.mask.clientHeight || this.content.clientHeight <= this.visibleHeight) {
      this.el.style.maxHeight = '100%';
      this.mask.style.display = 'none';
      this.el.style.paddingBottom = '0';
    } else {
      this.mask.style.display = 'block';
      this.el.style.paddingBottom = `${this.mask.clientHeight}px`;

      // Start height = expander height when closed
      this.startHeight = `${this.mask.clientHeight + this.visibleHeight}px`;

      // End height = expander height when opened
      this.endHeight = `${this.mask.clientHeight + this.content.clientHeight}px`;

      this.setHeight();
    }
  }

  /**
   * Get visible content's bounding
   *
   * @returns {number}
   */
  getVisibleHeight() {
    const { y: offsetTop, height } = this.el.getBoundingClientRect();
    return offsetTop < 0 ? height + offsetTop : height;
  }
}

componentRegistry.define('js-Expander', Expander);
